//
// Form-Upload
//

/* Dropzone */
.dropzone {
  min-height: 230px;
  border: 2px dashed $gray-400;
  background: #f6f9fe;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  .dz-message {
    font-size: 19px;
    width: 100%;
    color: #A96A3D;
  }
}

// Dark layout
body[data-layout-mode="dark"] {
  .dropzone {
    background: lighten($gray-dark-200, 1%);
    border-color: $gray-dark-300;
  }
}