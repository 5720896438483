.glass-card {
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(10px),
}

.color-primary {
  color: #ba8152;
}

.color-secondary {
  color: #f50057;
}

.color-white {
  color: #fff;
}



.color-btn {
  color: #00C6C1;
}

// custom css font from css
.font-weight-[number] {
  font-weight: [number];
}
.font-weight-bold {
  font-weight: bold;
}

//  Background color

.color-bg-btn {
  background: #A96A3D !important;
}

.color-bg-primary {
  background: #1C5C87;
}

.color-bg-secondary {
  background: #f50057;
}

.color-bg-white {
  background: #fff;
}
